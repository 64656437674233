import React from 'react'
import { RootLayout } from 'src/layouts'
import { useDimensions, breakpoints } from 'src/ui/dimensions'
import { Meta } from 'src/features/seo'
import { HBox } from 'src/ui/layout'
import { Header } from 'src/ui/typography'
import { useTheme, styled } from 'src/ui/theme'
import { Link } from 'gatsby'
import { ArrowLeft } from 'src/ui/icons/arrow-left'
import { ReferralPolicyList } from 'src/features/referral'

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1312px;
  margin: auto;

  @media (max-width: ${breakpoints.desktop}px) {
    margin: 0 64px;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    margin: 0 32px;
  }

  @media (max-width: ${breakpoints.mobile}px) {
    margin: 0 16px;
  }
`

const Title = styled(Header)`
  color: ${({ theme }) => theme.colors.black.bg};
`

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.brand.primary};
  text-decoration: none;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`

const IconWrap = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 4px;
`

export const ReferralPolicy = () => {
  const { isMobile } = useDimensions()
  const theme = useTheme()

  return (
    <RootLayout>
      <Meta
        title="Условия соглашения"
        description="Условия соглашения реферальной программы"
      />
      <TextContent>
        <HBox height={64} />

        <StyledLink to="/referral">
          <IconWrap>
            <ArrowLeft color={theme.colors.brand.primary} />
          </IconWrap>
          Реферальная программа
        </StyledLink>

        <HBox height={32} />

        <Title size={isMobile ? 'h4' : 'h2'}>Условия соглашения</Title>

        <HBox height={32} />

        <Title size="h5">
          Официальные правила участия в реферальной программе по подбору
          персонала компании KODE
        </Title>

        <HBox height={24} />

        <ReferralPolicyList />
      </TextContent>
    </RootLayout>
  )
}

export default ReferralPolicy
